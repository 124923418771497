import { WhatsappWebhook } from './whatsapp-webhook.service'

const PATH = 'whatsapp/'

class WhatsappMessageService extends WhatsappWebhook {
  setRead (data) {
    if (!this.provider) {
      throw new Error('Setting a provider is needed for this operation. i.e.: WhatsappMessageService.setProvider(...)')
    }
    return this.post(PATH + this.provider + '/mark-message-read', {
      type: 'MARK_MESSAGE_READ',
      ...data,
    })
  }
}

export default new WhatsappMessageService()
