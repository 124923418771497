import { Message } from './message'
export class MultimediaMessage extends Message {
  link = null
  caption = null
  simpleType = null
  mimeType = null
  content = null
  name = null
  extension = null
  size = null
  constructor (data, name, extension, size, simpleType, mimeType, link, caption, content = '') {
    if (data) {
      data.type = simpleType
    }
    super(data)
    if (!data) return
    this.link = link
    this.caption = caption
    this.simpleType = simpleType
    this.mimeType = mimeType
    this.content = content
    this.name = name
    this.extension = extension
    this.size = size
  }
}
