import { Message } from './message'
export class TextMessage extends Message {
  content = null
  constructor (data, content) {
    if (data) {
      data.type = 'text'
    }
    super(data)
    if (!data) return
    this.content = content
  }
}
