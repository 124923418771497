import { ChatReplacements } from './chat-replacements'
import { Message } from './message'
export class ButtonMessage extends Message {
  constructor (baseMessage) {
    super(baseMessage)
    this.content = baseMessage.callback_message ? baseMessage.callback_message.text : ''
    this.content = this.content.replace(ChatReplacements.FROM, m => ChatReplacements.TO[m])
  }

  static createFromSendTextMessage (sendTextMessage) {
    const baseMessage = Message.getBaseDataFromSendMessage(sendTextMessage)
    const message = new ButtonMessage(baseMessage)
    message.content = sendTextMessage.content
    message.content = message.content.replace(ChatReplacements.FROM, m => ChatReplacements.TO[m])
    return message
  }
}
