import { Message } from './message'
export class LocationMessage extends Message {
  longitude = null
  latitude = null
  name = null
  address = null

  constructor (data, longitude = 0, latitude = 0, name = '', address = '') {
    if (data) {
      data.type = 'location'
    }
    super(data)
    if (!data) return
    this.longitude = longitude
    this.latitude = latitude
    this.name = name
    this.address = address
  }
}
