import WhatsappMultimediaService from '../../services/whatsapp-multimedia.service'
import { Message } from './message'
export class TemplateMessage extends Message {
  templateId = null
  substitutions = { }
  template = {}

  // eslint-disable-next-line no-useless-constructor
  constructor (baseMessage) {
    super(baseMessage)
    if (baseMessage.used_template) {
      this.template = baseMessage.used_template
      this.template.head_board = this.template ? JSON.parse(this.template.head_board) : this.template
      this.templateId = baseMessage.used_template ? baseMessage.used_template.id : null
    }
    // this.content = baseMessage.text_message ? baseMessage.text_message.body : ''
  }

  setSubstitutions (provider, substitutions) {
    this.substitutions = substitutions
    if (substitutions.header) {
      this.setMultimedia(provider, this)?.then(metaData => {
        if (!metaData) {
          return
        }
        // console.log(metaUrl)
        if (metaData.data) {
          const mimeType = metaData.headers['content-type']
          this.substitutions.header.url = 'data:' + mimeType + ';base64,' + metaData.data
        }
      })
    }
  }

  static createFromSendTemplateMessage (provider, sendTemplateMessage) {
    const baseMessage = Message.getBaseDataFromSendMessage(sendTemplateMessage)
    const message = new TemplateMessage(baseMessage)
    message.templateId = sendTemplateMessage.templateId
    message.substitutions = sendTemplateMessage.substitutions
    message.setMultimedia(provider, sendTemplateMessage)
    return message
  }

  setMultimedia (provider, message) {
    if (!message.substitutions.header.id_file) {
      return
    }
    // Get data from Meta
    const data = {
      type: 'RETRIEVES',
      message_id: message._id,
      msisdn: this.contact.msisdn_user,
    }

    WhatsappMultimediaService.setProvider(provider)
    return WhatsappMultimediaService.getMultimediaUrl(data)

    // const metaData = await WhatsappMultimediaService.getMultimediaUrl(data)

    // if (!metaData) {
    //   return
    // }
    // // console.log(metaUrl)
    // const mimeType = metaData.headers['content-type']
    // this.substitutions.header.url = 'data:' + mimeType + ';base64,' + metaData.data
  }
}
