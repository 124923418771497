import { Message } from './message'
export class InteractiveMessage extends Message {
  interactive = null
  constructor (data, interactive) {
    if (data) {
      data.type = 'interactive'
    }
    super(data)
    if (!data) return
    this.interactive = interactive
  }
}
