import { Message } from './message'
export class TemplateMessage extends Message {
  templateId = null
  substitutions = {
    header: '',
    body: [],
    buttons: [],
  }

  constructor (data, templateId) {
    if (data) {
      data.type = 'template'
    }
    super(data)
    if (!data) return
    this.templateId = templateId
  }
}
