export class ChatReplacements {
  static get FROM () {
    return /(?:\r\n|\r|\n|\\r\\n|\\r|\\n|\\"|\\'|\\”|\\’)/g
  }

  static get TO () {
    return {
      '\r\n': '<br>',
      '\r': '<br>',
      '\n': '<br>',
      '\\r\\n': '<br>',
      '\\r': '<br>',
      '\\n': '<br>',
      '\\"': '"',
      "\\'": "'",
      '\\”': '”',
      '\\’': '’',
    }
  }
}
