import { Message } from './message'
export class ChangeAgentMessage extends Message {
  agentId = null
  agentTypeId = null
  agentName = null
  constructor (baseMessage) {
    super(baseMessage)
    this.agentId = baseMessage.agent_id
    this.agentTypeId = baseMessage.agent_type_id
    this.agentName = baseMessage.agent_name
  }
}
