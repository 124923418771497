import { Message } from './message'

export class InteractiveAnswerMessage extends Message {
    static BUTTON_REPLY = 'button_reply'
    static LIST_REPLY = 'list_reply'


    interactiveAnswer = {}
    content = ''
    constructor (baseMessage) {
      super(baseMessage)
      this.type = 'interactive-answer'
      this.interactiveAnswer = baseMessage.interactive_answer
      this.content = this.interactiveAnswer?.title
      if (this.interactiveAnswer.type === InteractiveAnswerMessage.LIST_REPLY) {
        if (this.interactiveAnswer.description) {
          this.content += '<br>' + this.interactiveAnswer.description
        }
      }
    }

    static createFromSendInteractiveAnswer (sendInteractiveAnswer) {
        const baseMessage = Message.getBaseDataFromSendMessage(sendInteractiveAnswer)
        const message = new InteractiveAnswerMessage(baseMessage)
        message.content = sendInteractiveAnswer.body
        return message
    }
}
