import axios from 'axios'
import { WhatsappWebhook } from './whatsapp-webhook.service'

const PATH = 'whatsapp/'

class WhatsappMultimediaService extends WhatsappWebhook {
  getMultimediaUrl (params = {}) {
    if (!this.provider) {
      throw new Error('Setting a provider is needed for this operation. i.e.: WhatsappMultimediaService.setProvider(...)')
    }
    return this.postResult(PATH + this.provider + '/retrieve-media-url', params)
  }

  getMultimedia (url, token) {
    delete axios.defaults.headers.common['X-Requested-With']
    // return axios.get(url, {
    //   headers: {
    //     'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.0.0 Safari/537.36',
    //     Authorization: `Bearer ${token}`,
    //   },
    // })
    return fetch(url, {
      method: 'get',
      headers: {
        'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.0.0 Safari/537.36',
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
      },
    })
  }
}

export default new WhatsappMultimediaService()
