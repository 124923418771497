import UserInboxCloseReasonService from '@/services/whatsapp/user-Inbox-close-reason.service.js'
import EventBus from '@/util/EventBus'
import { ChatProps } from '@/classes/chat/chat-props'

export default {
  name: 'CloseConversation',
  components: {
  },

  props: {
    value: { type: Boolean, required: true },
    chatProps: { type: Object, required: true, default: () => { return new ChatProps() } },
  },

  emits: [
    'close-conversation',
  ],

  data () {
    return {
      dialogConfirmCloseConversation: false,
      closeReasons: [],
      closeReason: null,

    }
  },

  watch: {
    value (nv) {
      this.dialogConfirmCloseConversation = nv
    },
    dialogConfirmCloseConversation (nv) {
      this.$emit('input', this.dialogConfirmCloseConversation)
    },
    blocklistStatus (nv) {
      this.selectedBlacklistTypes = nv
    },
    'chatProps.currentUserMsisdn' (newValue, oldValue) {
      UserInboxCloseReasonService.getAllReasons({
        msisdn: newValue,
      }).then((response) => {
        this.closeReasons = response
      })
    },
  },

  computed: { },

  methods: {
    loadCloseReasons () {
      UserInboxCloseReasonService.getAllReasons({
        msisdn: this.chatProps.currentUserMsisdn,
      }).then((response) => {
        this.closeReasons = response
      })
    },
    closeDialog () {
      this.value = false
    },
    closeConversation () {
      this.$emit('close-conversation', this.closeReason)
    },
  },
  mounted () {
    EventBus.$on('changedClosedReasons', () => {
      this.loadCloseReasons()
    })
  },
}
