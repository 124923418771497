import { Message } from './message'
export class LocationMessage extends Message {
  longitude = null
  latitude = null
  name = null
  address = null

  constructor (baseMessage) {
    super(baseMessage)
    if (baseMessage.location_message) {
      this.longitude = baseMessage.location_message.longitude
      this.latitude = baseMessage.location_message.latitude
      this.name = baseMessage.location_message.name
      this.address = baseMessage.location_message.address
    }
  }

  static createFromSendLocationMessage (sendLocationMessage) {
    const baseMessage = Message.getBaseDataFromSendMessage(sendLocationMessage)
    const message = new LocationMessage(baseMessage)
    message.longitude = sendLocationMessage.longitude
    message.latitude = sendLocationMessage.latitude
    message.name = sendLocationMessage.name
    message.address = sendLocationMessage.address
    return message
  }
}
