import { Message } from './message'
export class ContactBlockMessage extends Message {
  status = 1
  blockType = 0
  constructor (baseMessage) {
    super(baseMessage)
    this.status = baseMessage.status
    this.blockType = baseMessage.block_type
  }
}
