import { Message } from './message'
import { ChatReplacements } from './chat-replacements'
import $t from '../../util/Translator'
export class UnsupportedMessage extends Message {
    reason = ''
    content = ''
    constructor (baseMessage) {
        super(baseMessage)
        this.reason = $t('Message type is currently not supported')
        this.content = baseMessage.content ? baseMessage.content : ''
        this.content = this.content.replace(ChatReplacements.FROM, m => ChatReplacements.TO[m])
    }

    static createFromSendUnsupportedMessage (sendUnsupportedMessage) {
        const baseMessage = Message.getBaseDataFromSendMessage(sendUnsupportedMessage)
        const message = new UnsupportedMessage(baseMessage)
        message.reason = sendUnsupportedMessage.reason
        message.content = sendUnsupportedMessage.content
        message.content = message.content.replace(ChatReplacements.FROM, m => ChatReplacements.TO[m])
        return message
    }
}
