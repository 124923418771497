import moment from 'moment'
import momenttz from 'moment-timezone'

export class Message {
  _id = null
  indexId = null
  content = ''
  senderId = null
  username = null
  avatar = null // 'https://picsum.photos/200'
  date = null
  timestamp = null
  dateTime = null
  system = false
  saved = false
  distributed = false
  seen = false
  deleted = false
  failure = false
  disableActions = true
  disableReactions = true
  type = null
  agent = null
  sessionId = null
  contact = null

  constructor (baseMessage) {
    const user = JSON.parse(localStorage.getItem('user'))
    this._id = baseMessage.id
    this.indexId = baseMessage.id
    this.senderId = baseMessage.msisdn
    this.date = this.formatDateLocal(baseMessage.created_at, 'YYYY-MM-DD', user.userTimezone)
    this.timestamp = this.formatDateLocal(baseMessage.created_at, 'HH:mm', user.userTimezone)
    this.dateTime = this.formatDateLocal(baseMessage.created_at, 'YYYY-MM-DD HH:mm:ss', user.userTimezone)
    this.type = baseMessage.type
    this.agent = baseMessage.agent
    this.sessionId = baseMessage.session_id
    this.contact = baseMessage.contact
  }

  formatDateLocal (date, format, timezone) {
    if (!date) {
      return
    }
    const d = momenttz.tz(date, timezone).format()
    return moment(d).local().format(format)
  }

  static getBaseDataFromSendMessage (sendMessage) {
    return {
      id: sendMessage.id,
      msisdn: sendMessage.msisdnFrom,
      created_at: new Date(),
      type: sendMessage.type,
    }
  }

  setToRead () {
    this.saved = true
    this.distributed = true
    this.seen = true
  }

  setToSent () {
    this.saved = true
    this.distributed = false
    this.seen = false
  }

  setToDelivered () {
    this.saved = true
    this.distributed = true
    this.seen = false
  }
}
