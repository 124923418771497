import { Message } from './message'
import { ChatReplacements } from './chat-replacements'
export class TextMessage extends Message {
  constructor (baseMessage) {
    super(baseMessage)
    this.content = baseMessage.text_message ? baseMessage.text_message.body : ''
    this.content = this.content.replace(ChatReplacements.FROM, m => ChatReplacements.TO[m])
  }

  static createFromSendTextMessage (sendTextMessage) {
    const baseMessage = Message.getBaseDataFromSendMessage(sendTextMessage)
    const message = new TextMessage(baseMessage)
    message.content = sendTextMessage.content
    message.content = message.content.replace(ChatReplacements.FROM, m => ChatReplacements.TO[m])
    return message
  }
}
