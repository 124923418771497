import WhatsappMultimediaService from '@/services/whatsapp-multimedia.service'
import { Message } from './message'
import { ChatReplacements } from './chat-replacements'
export class MultimediaMessage extends Message {
  providerName = null
  link = null
  idFile = null
  caption = null
  filename = null
  files = []
  content = ''

  constructor (provider, baseMessage) {
    super(baseMessage)
    if (baseMessage.multimedia_message) {
      this.providerName = baseMessage.multimedia_message.provider_name
      this.link = baseMessage.multimedia_message.link
      this.idFile = baseMessage.multimedia_message.id_file
      this.caption = baseMessage.multimedia_message.caption
      this.filename = baseMessage.multimedia_message.filename
      this.content = baseMessage.multimedia_message.content ? baseMessage.multimedia_message.content : ''
      this.content = this.content.replace(ChatReplacements.FROM, m => ChatReplacements.TO[m])
    }

    if (['image', 'video', 'audio', 'document'].includes(this.type)) {
      const file = {
        name: this.filename,
        size: 67351,
        type: null,
        audio: true,
        url: this.link,
        preview: this.link,
      }

      switch (this.type) {
        case 'image':
          file.type = 'png'
          break
        case 'video':
          file.type = 'mp4'
          break
        case 'audio':
          file.type = 'mp3'
          break
        case 'document':
          file.type = 'txt'
          break
        default:
          file.type = 'txt'
          break
      }

      this.files = [file]
    }

    this.setMultimedia(provider, baseMessage.multimedia_message)
  }

  async setMultimedia (provider, message) {
    if (!this.idFile) {
      return
    }
    // Get data from Meta
    const data = {
      type: 'RETRIEVES',
      message_id: message.message_id,
      msisdn: this.contact.msisdn_user,
    }

    WhatsappMultimediaService.setProvider(provider)
    const metaData = await WhatsappMultimediaService.getMultimediaUrl(data)

    if (!metaData) {
      return
    }
    // console.log(metaUrl)
    const mimeType = metaData.headers['content-type']
    this.link = 'data:' + mimeType + ';base64,' + metaData.data
    if (this.files.length) {
      this.files[0].url = this.link
      this.files[0].preview = this.link
    }
  }

  static createFromSendMultimediaMessage (sendMultimediaMessage) {
    const baseMessage = Message.getBaseDataFromSendMessage(sendMultimediaMessage)
    const message = new MultimediaMessage(baseMessage)
    message.providerName = ''
    message.link = sendMultimediaMessage.link
    message.caption = sendMultimediaMessage.caption
    message.filename = sendMultimediaMessage.name
    message.content = sendMultimediaMessage.content
    message.content = message.content.replace(ChatReplacements.FROM, m => ChatReplacements.TO[m])
    message.type = sendMultimediaMessage.simpleType

    message.files = [
      {
        name: sendMultimediaMessage.name,
        size: sendMultimediaMessage.size,
        type: sendMultimediaMessage.extension,
        url: message.link,
        preview: message.link,
      },
    ]

    return message
  }
}
