
import { Service } from '@/services/service'

const PATH = 'whatsapp/contact-notes/'

class ContactNoteService extends Service {
  findAll (params) {
    return this.get(PATH + 'index', params)
  }

  create (params) {
    return this.post(PATH + 'create', params)
  }

  save (params) {
    return this.post(PATH + 'save', params)
  }

  delete (params) {
    return this.post(PATH + 'delete', params)
  }
}

export default new ContactNoteService()
