import { Message } from './message'
import WhatsappMultimediaService from '../../services/whatsapp-multimedia.service'

export class InteractiveMessage extends Message {
    interactiveMessage = {}
    body = ''
    footer = null

    constructor (baseMessage) {
      super(baseMessage)
      this.interactiveMessage = baseMessage.interactive_message
      this.interactiveMessage.header = {
        type: baseMessage.interactive_message?.type_header,
        url: baseMessage.interactive_message?.link,
        text: baseMessage.interactive_message?.text_header,
        mimeType: baseMessage.interactive_message?.mime_type,
        id_file: baseMessage.interactive_message?.file_id,
      }
      delete Object.assign(this.interactiveMessage, { buttons: this.interactiveMessage.interactive_message_buttons }).interactive_message_buttons
      delete Object.assign(this.interactiveMessage, { sections: this.interactiveMessage.interactive_message_sections }).interactive_message_sections
      this.body = baseMessage.interactive_message?.body ? baseMessage.body : ''
      this.footer = baseMessage.interactive_message?.footer ? baseMessage.footer : ''
    }

    static createFromSendUnsupportedMessage (sendInteractiveMessage) {
        const baseMessage = Message.getBaseDataFromSendMessage(sendInteractiveMessage)
        const message = new InteractiveMessage(baseMessage)
        message.content = sendInteractiveMessage.body
        return message
    }

    setMultimedia (provider, message) {
      if (!message.substitutions.header.id_file) {
        return
      }
      // Get data from Meta
      const data = {
        type: 'RETRIEVES',
        message_id: message._id,
        msisdn: this.contact.msisdn_user,
      }

      WhatsappMultimediaService.setProvider(provider)
      return WhatsappMultimediaService.getMultimediaUrl(data)

      // const metaData = await WhatsappMultimediaService.getMultimediaUrl(data)

      // if (!metaData) {
      //   return
      // }
      // // console.log(metaUrl)
      // const mimeType = metaData.headers['content-type']
      // this.substitutions.header.url = 'data:' + mimeType + ';base64,' + metaData.data
    }
}
