import ContactsService from '@/services/whatsapp/contacts.service.js'
import UserInboxTagService from '@/services/whatsapp/user-inbox-tag-service.service.js'
import { Country } from '@/util/Country.js'
import SelectCountry from '@/components/ContactSource/SelectCountry/SelectCountry.vue'
import CountryService from '@/services/country.service'
import DateRange from '@/components/DateRange/DateRange.vue'
import EventBus from '@/util/EventBus'
import { Contact } from '@/models/whatsapp/Contact.js'

export default {
  name: 'ContactEditDialog',
  components: {
    SelectCountry,
    DateRange,
  },
  props: {
    selectedUserMsisdn: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      dialogEditContact: false,
      options: {},
      searchTagText: '',
      tags: [],
      Country: Country,
      countries: [],
      confirmContactConsent: true,
      contact: new Contact(),
      routes: {
        list: [],
      },
      eventUuid: '',
    }
  },
  computed: {
    contactSources () {
      return [
        { value: 1, text: this.$t('MANUAL').toString() },
        { value: 2, text: 'WHATSAPP' },
      ]
    },
  },
  created () {
  },
  watch: {},
  mounted () {
    EventBus.$on('ChangeCurrentUserMsisdn', this.changeMsisdn)
    EventBus.$on('hook:beforeDestroy', () => {
      EventBus.$off('ChangeCurrentUserMsisdn', this.changeMsisdn)
    })

    this.getAllCountries()
    window.addEventListener('keydown', this.checkESCKey)

    EventBus.$on('ContactEditDialog:show', (data) => {
      this.eventUuid = data.eventUuid
      this.contact = data.contact
      this.routes.list = data.routes.list
      this.dialogEditContact = true
      this.contact.msisdnUser = data.selectedUserMsisdn
    })
  },
  destroyed () {
    window.removeEventListener('keydown', this.checkESCKey)
  },
  methods: {
    changeMsisdn (msisdn) {
      this.allTags(msisdn)
    },
    checkESCKey (event) {
      if (event.code === 'Escape' && this.dialogEditContact) {
        this.closeDialog()
      }
    },
    copyToClipboard (text) {
      navigator.clipboard.writeText(text ?? '')
    },
    closeDialog () {
      this.dialogEditContact = false
      this.$emit('close-contact-dialog')
    },
    closeDialogClickOutside (e) {
      if (['v-overlay__scrim', 'v-main__wrap'].includes(e.target.className)) {
        this.closeDialog()
      }
    },
    changedTag () {
      this.searchTagText = ''
    },
    getAllCountries () {
      CountryService
        .getAllCountries()
        .then(
          (response) => {
            this.countries = response.map((country) => {
              return {
                value: country.iso_code_2,
                text: `${country.name} +(${country.prefix})`,
              }
            })
          },
          () => {},
        )
    },
    removeTag (item) {
      this.contact.tags = this.contact.tags.filter(tagId => { return tagId !== item.id })
    },
    allTags (msisdn) {
      UserInboxTagService.allTags({ msisdn }).then((response) => {
        this.tags = response
      })
    },
    save () {
      const data = this.contact
      ContactsService.save(data).then((response) => {
        EventBus.$emit('ContactEditDialog:updateContact', { eventUuid: this.eventUuid, contact: this.contact })
        EventBus.$emit('showAlert', 'success', this.$t('Contacto actualizado correctamente.').toString())
        this.closeDialog()
      })
    },
  },
}
