import { v4 as uuidv4 } from 'uuid'

export class Message {
  id = null
  type = null
  userId = null
  msisdnFrom = null
  msisdnTo = null

  constructor (data) {
    if (!data) return
    this.id = data.id
    this.type = data.type
    this.userId = data.userId
    this.msisdnFrom = data.msisdnFrom
    this.msisdnTo = data.msisdnTo
   }

   static getNewCurrentCommonMessage (currentUserMsisdn, contactMsisdn) {
    const user = JSON.parse(localStorage.getItem('user'))
    const commonMessage = new Message()
    commonMessage.id = uuidv4()
    commonMessage.userId = user.id
    commonMessage.msisdnFrom = currentUserMsisdn
    commonMessage.msisdnTo = contactMsisdn
    return commonMessage
   }
}
